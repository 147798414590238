<template>
    <div>
        <v-row class="mx-4" style="width: 95%">
            <v-row class="mx-4" style="width: 95%; justify-content: center;">
                <!-- Filter by campaign -->
                Select a date (or date range), to view email digests for the range
            </v-row>
            <v-row class="mx-4 py-4 d-flex jusity-start" style="width: 95%">
                <v-col cols="12">
                    <div>
                        <v-row>
                            <v-col cols="5">
                                <v-date-picker
                                    v-model="selectedDate"
                                    range
                                    no-title
                                    scrollable
                                    @input="updateDate"
                                />
                            </v-col>
                            <v-col cols="0">
<v-row style="flex-direction: column; padding: 5px; width: 10px;">
    <v-btn @click="goToDateRange(0, 0); generateEmailDigest()" class="my-2" color="primary" dark>Today Only</v-btn>
    <v-btn @click="goToDateRange(-7, 0); generateEmailDigest()" class="my-2" color="#2a6d80" dark>Past Week Only</v-btn>
    <v-btn @click="goToDateRange(0, 7); generateEmailDigest()" class="my-2" color="#00426a" dark>Coming Week Only</v-btn>
    <v-btn @click="goToDateRange(-31, 0); generateEmailDigest()" class="my-2" color="#00729e" dark>Past Month Only</v-btn>
    <v-btn @click="goToDateRange(0, 31); generateEmailDigest()" class="my-2" color="#248cb9" dark>Coming Month Only</v-btn>
  </v-row>
  <v-row justify="left">
    <div style="color: orange;">{{errorMessage}}</div>
  </v-row>
</v-col>



                            <v-col cols="4" style="padding-left: 150px;">
                                <v-select
                                  v-model="selectedDeliveryTypes"
                                  label="Display by Delivery Type"
                                  outlined
                                  multiple
                                  @change="validDeliveryTypes(); generateEmailDigest();"
                                  :items="deliveryTypes"
                                  :item-text="(item) => item.name"
                                  :item-value="(item) => item.id"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-row>
        <div class="pa-2">{{digests.length}} email {{(digests.length == 1 ? "digest" : "digests")}} for {{(validDateRange() ? prettyMomentRange(selectedDate) : "(please select valid dates)")}}</div>
        <v-divider class="pa-2"></v-divider>
        <v-row class='mx-4' style="width: 95%;">
            <v-col cols="12">
                <div v-for="(digest, index) in digests" :key="index">
                    <v-row>
                        <v-col cols="2" class="primary pa-2" style="opacity: 1;">
                            <span>{{digest.mediaOwner}}</span>
                        </v-col>
                        <v-col cols="5" class="tertiary pa-2" style="opacity: 1;">
                            <span>{{ digest.digestType}}</span> - <span> {{prettyMoment(digest.requestDate)}}</span>
                        </v-col>
                        <v-col cols="4" class="secondary pa-2" style="opacity: 1;">
                            <span>Campaigns: {{ digest.campaigns}}</span>
                        </v-col>
                    </v-row>
                    <v-row class="pb-6">
                        <v-col cols="11" class="pa-0">
                            <div class="pa-4" :style="digest.active ? {border: '1px #c1c1c1 solid'} : {border: '1px #c1c1c1 dashed'}" v-html=digest.message></div>
                        </v-col>
                        <v-col cols="1">
                            <v-btn color="primary" block small @click="copyEmailToClipboard(digest)">
                                <v-icon>mdi-clipboard</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// Services
import ReportingController from '@/services/controllers/Reporting'
import CampaignController from '@/services/controllers/Campaign'
import moment from 'moment';

export default {
    name: 'ReportEmailDigest',
    props: {
    },
    data: () => ({
        // Email Digests
        digests: [],
        // Date pickers
        selectedDate: null,
        // Misc
        successMessage: false,
        errorMessage: "",
        deliveryTypes: [],
        selectedDeliveryTypes: [],
    }),
    mounted() {
        this.successMessage= false
        this.goToDateRange(0,0)
    },
    watch: {
    }, 
    computed: {

    },
    async created () {
      // Get Delivery Types
      const res = await CampaignController.deliveryTypes()
      this.deliveryTypes = res.data
      this.selectedDeliveryTypes = [this.deliveryTypes.find(x => x.shortName == "linear").id] // Default to linear
      this.generateEmailDigest();
    },
    methods: {
        copyEmailToClipboard(digest) {
            var msg = digest.message;
            digest.active = true;
            function listener(e) {
                e.clipboardData.setData('text/html', msg)
                e.clipboardData.setData('text/plain', msg)
                e.preventDefault()
            }
            document.addEventListener('copy', listener)
            document.execCommand('copy')
            document.removeEventListener('copy', listener)
            this.$root.$emit("snackbarSuccess", "Copied to Clipboard!")
        },
        
        prettyMomentRange: function (dateRange) {
            var start = moment(dateRange[0]).format('DD MMMM YYYY');
            var end = moment(dateRange[1]).format('DD MMMM YYYY');
            if(start == end)
                return this.prettyMoment(start,true)
            return this.prettyMoment(start) + " to " + this.prettyMoment(end);
        },
        prettyExtra(dt) {
            var today = moment().format('DD MMMM YYYY');
            var tomorrow = moment().add(1,'days').format('DD MMMM YYYY');
            var yesterday = moment().add(-1,'days').format('DD MMMM YYYY');
            var extra = '';

            // is it today?
            if (dt == today) {
                extra = ' (Today)';
            } else if (dt == tomorrow) {
                extra = ' (Tomorrow)';
            } else if (dt == yesterday) {
                extra = ' (Yesterday)';
            } else {
                // Check if it's within the same week
                var startOfWeek = moment().startOf('week').format('DD MMMM YYYY');
                var endOfWeek = moment().endOf('week').format('DD MMMM YYYY');
                if (moment(dt).isBetween(startOfWeek, endOfWeek, null, '[]')) {
                    extra = ' (This week)';
                } else {
                    // Check if it's within the same month
                    var startOfMonth = moment().startOf('month').format('DD MMMM YYYY');
                    var endOfMonth = moment().endOf('month').format('DD MMMM YYYY');
                    if (moment(dt).isBetween(startOfMonth, endOfMonth, null, '[]')) {
                        extra = ' (This month)';
                    } else {
                        // Add more checks or customizations as needed
                    }
                }
            }
            return extra;
        },

        prettyMoment: function (date, includeExtra) {
            var dt = moment(date).format('DD MMMM YYYY');
            return dt + (includeExtra ? this.prettyExtra(dt) : '');
        },
        updateDate(value) {
            // Handle date selection if needed
            this.digests = [];
            this.generateEmailDigest();
        },
        goToDateRange(s,e) {
            var start = moment().startOf('day').add(s, 'days').toISOString().split('T')[0];
            var end = moment().startOf('day').add(e, 'days').toISOString().split('T')[0];
            this.selectedDate = [start, end]
        },
        validDateRange() {
            if (this.selectedDate == null) {
                this.errorMessage = "Please select a date range or one of the calendar options."
                return false;
            }
            else if (this.selectedDate[0] == null) {
                this.errorMessage = "Please select a start date."
                return false;
            }
            else if (this.selectedDate[1] == null) {
                this.errorMessage = "Please select an end date."
                return false;
            }
            else if (moment(this.selectedDate[0]) > moment(this.selectedDate[1]))
            {
                var tmp1 = this.selectedDate[1];
                this.selectedDate[1] = this.selectedDate[0];
                this.selectedDate[0] = tmp1;
            }
            return true;
        },
        validDeliveryTypes() {
            if (this.selectedDeliveryTypes.length == 0 && this.deliveryTypes.length > 0) {
                this.errorMessage = "Please select at least one delivery type."
                return false;
            }
            return true;
        },
        // Generate Email Digest
        async generateEmailDigest(){
            if(!this.validDateRange())
                return;
            if(!this.validDeliveryTypes())
                return;
            this.errorMessage = "";
            this.successMessage = true
            var startDate = this.selectedDate[0]
            var endDate = this.selectedDate[1]
            await ReportingController.getReportEmailDigest(startDate, endDate, this.selectedDeliveryTypes.join(','))
            .then((res) => {
                this.digests = res.data
                this.deactivateAll(this.digests)
                this.$root.$emit("snackbarSuccess", "Email digest obtained!")
            })
            .catch((error) => {
                this.$root.$emit("snackbarError", error.message)
            })
        },
        deactivateAll(arr) {
            for (var i = 0; i < arr.length; i++) {
                arr[i].active = false;
            }
        }
    }
}
</script>