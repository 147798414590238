var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-4",staticStyle:{"width":"95%"}},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.campaigns,"item-text":function (item) { return item.name; },"item-value":"id","label":"Choose Campaign","outlined":"","return-object":""},on:{"change":_vm.campaignChange},model:{value:(_vm.selectedCampaign),callback:function ($$v) {_vm.selectedCampaign=$$v},expression:"selectedCampaign"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-select',{attrs:{"disabled":!_vm.selectedCampaign,"items":_vm.bursts,"item-text":function (item) { return item.name; },"item-value":"id","label":"Choose Burst","outlined":"","clearable":"","return-object":""},on:{"change":_vm.burstChange},model:{value:(_vm.selectedBurst),callback:function ($$v) {_vm.selectedBurst=$$v},expression:"selectedBurst"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-select',{attrs:{"disabled":!_vm.selectedBurst,"items":_vm.screens,"item-text":function (item) { return item.screen.name + ' - ' + item.screen.mediaOwner.friendlyName; },"item-value":"id","label":"Choose Screen","outlined":"","clearable":"","return-object":""},model:{value:(_vm.selectedScreen),callback:function ($$v) {_vm.selectedScreen=$$v},expression:"selectedScreen"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('v-select',{attrs:{"items":_vm.dayParts,"label":"Choose part of day","outlined":"","clearable":"","return-object":""},model:{value:(_vm.selectedDayPart),callback:function ($$v) {_vm.selectedDayPart=$$v},expression:"selectedDayPart"}})],1)],1),_c('v-row',{staticClass:"mx-4 mt-n6",staticStyle:{"width":"95%"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"disabled":!_vm.selectedCampaign,"value":_vm.selectedDate,"outlined":"","range":"","label":"Start/End Date","append-icon":"mdi-calendar","readonly":"","clearable":""}},'v-combobox',attrs,false),on))]}}])},[_c('div',{staticStyle:{"background-color":"black"}},[_c('v-row',[_c('v-col',[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"range":"","no-title":"","scrollable":""},on:{"input":_vm.updateDate},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c('v-col',{staticClass:"py-4",staticStyle:{"gap":"3em"}},[_c('v-btn',{on:{"click":function($event){return _vm.goToDateRange(0,0)}}},[_vm._v("Today Only")]),_c('v-btn',{on:{"click":function($event){return _vm.goToDateRange(-7,0)}}},[_vm._v("Past Week Only")]),_c('v-btn',{on:{"click":function($event){return _vm.goToDateRange(0,7)}}},[_vm._v("Coming Week Only")]),_c('v-btn',{on:{"click":function($event){return _vm.goToDateRange(-31,0)}}},[_vm._v("Past Month Only")]),_c('v-btn',{on:{"click":function($event){return _vm.goToDateRange(0,31)}}},[_vm._v("Coming Month Only")]),_c('v-checkbox',{attrs:{"label":"View All Dates"},model:{value:(_vm.overrideDates),callback:function ($$v) {_vm.overrideDates=$$v},expression:"overrideDates"}})],1)],1)],1)])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"disabled":!_vm.selectedCampaign,"value":_vm.selectedStartTime,"outlined":"","range":"","label":"Start Hour","append-icon":"mdi-clock-time-four-outline","readonly":"","clearable":""}},'v-combobox',attrs,false),on))]}}])},[_c('v-time-picker',{attrs:{"format":"24hr"},model:{value:(_vm.selectedStartTime),callback:function ($$v) {_vm.selectedStartTime=$$v},expression:"selectedStartTime"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"disabled":!_vm.selectedCampaign,"value":_vm.selectedEndTime,"outlined":"","range":"","label":"End Hour","append-icon":"mdi-clock-time-four-outline","readonly":"","clearable":""}},'v-combobox',attrs,false),on))]}}])},[_c('v-time-picker',{attrs:{"format":"24hr"},model:{value:(_vm.selectedEndTime),callback:function ($$v) {_vm.selectedEndTime=$$v},expression:"selectedEndTime"}})],1)],1)],1),_c('v-row',{staticClass:"mx-8 mt-n6",staticStyle:{"gap":"3em"}},[_c('v-checkbox',{attrs:{"label":"Export Text"},model:{value:(_vm.exportText),callback:function ($$v) {_vm.exportText=$$v},expression:"exportText"}}),_c('v-checkbox',{attrs:{"label":"Export Overlays"},model:{value:(_vm.exportOverlays),callback:function ($$v) {_vm.exportOverlays=$$v},expression:"exportOverlays"}})],1),_c('v-row',{staticClass:"mx-7",staticStyle:{"width":"93%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{style:({ width: '100%', transition: 'all 0.2s ease-in-out' }),attrs:{"color":hover ? 'primary darken-1' : 'primary',"elevation":"0"},on:{"click":_vm.generateReport}},[_vm._v(" Generate Report ")])]}}])}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.successMessage),expression:"successMessage"}]},[_vm._v("Your report is now being generated, and will be emailed to you shortly.")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }