var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"px-4 py-1",staticStyle:{"gap":"1em"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticStyle:{"width":"100%"},attrs:{"autofocus":"","outlined":"","label":"Search","append-icon":"mdi-magnify"},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.searchCampaign)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.searchCampaign = ''}}},[_vm._v(" mdi-close ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.searchCampaign),callback:function ($$v) {_vm.searchCampaign=$$v},expression:"searchCampaign"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-select',{staticStyle:{"width":"100%"},attrs:{"items":_vm.itemsPerPageOptions,"label":"Page Size","outlined":""},on:{"change":function($event){_vm.getReportJobProgress(); _vm.gettingReportsLoader = true;}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g({attrs:{"large":"","icon":"","color":hover || _vm.reportMode ? 'primary' : 'white',"loading":_vm.gettingReportsLoader},on:{"click":function($event){_vm.reportMode = !_vm.reportMode
                _vm.getReportJobProgress();
                _vm.gettingReportsLoader = true}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-account ")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Show All/Personal")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-btn',_vm._g({attrs:{"large":"","icon":"","color":hover || _vm.reportMode ? 'primary' : 'white',"loading":_vm.gettingReportsLoader},on:{"click":function($event){_vm.getReportJobProgress(); _vm.gettingReportsLoader = true;}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-refresh ")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Refresh")])]),_c('div',{staticClass:"ml-auto",staticStyle:{"width":"150px"},on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();},"click":function($event){return _vm.$refs.reportsUpload.click()}}},[_c('input',{ref:"reportsUpload",attrs:{"id":"reportsUpload","type":"file","multiple":"","hidden":""},on:{"click":function($event){_vm.$refs.reportsUpload.value = ''},"change":_vm.uploadFile}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('div',_vm._g(_vm._b({ref:"dragDiv",staticClass:"rounded",staticStyle:{"background-color":"transparent","padding":"0.66em 0em","transition":"0.1s"},style:(hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}),on:{"drop":_vm.dragFile,"dragleave":function($event){_vm.$refs.dragDiv.style.backgroundColor = 'transparent'},"dragover":function($event){_vm.$refs.dragDiv.style.backgroundColor = 'grey'}}},'div',attrs,false),on),[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-cloud-upload")])],1)])]}}],null,true)})]}}])},[_c('span',[_vm._v("Drag/Drop Reports")])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.reportJobHeaders,"items":_vm.reportJobs(),"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.statusLine",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"d-flex justify-between align-center ml-n4",style:({ width:'2px', backgroundColor: _vm.statusColor(item.mode) }),attrs:{"width":"2px"}})]}},{key:"expanded-item",fn:function(ref){
                var item = ref.item;
return [(_vm.reportJobStatus(item).status === 'processed')?_c('td',{attrs:{"colspan":"10"}},[_c('div',{domProps:{"innerHTML":_vm._s(item.htmlMessage)}})]):_c('td',{attrs:{"colspan":"10"}},[_c('p',[_vm._v("No more info at this stage")])])]}},{key:"item.fileName",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('small',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},style:(hover ? {textDecoration: 'underline'} : {textDecoration: 'none'}),on:{"click":function($event){return _vm.downloadItem(item)}}},'small',attrs,false),on),[_vm._v(_vm._s(item.media.originalFileName))])]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Download report")])])]}},{key:"item.progress",fn:function(ref){
                var item = ref.item;
return [_c('v-progress-linear',{staticClass:"mt-2",attrs:{"color":"primary","rounded":"","height":"20","value":_vm.reportJobStatus(item).percentage}}),_c('small',[_c('pre',[_vm._v(_vm._s(_vm.reportJobStatus(item).message))])])]}},{key:"item.mediaOwner",fn:function(ref){
                var item = ref.item;
return [(item.mode==='prepare')?_c('v-select',{attrs:{"outlined":"","items":_vm.mediaOwners,"label":"Media Owner*","rules":_vm.requiredRule,"item-text":"friendlyName","return-object":"","dense":true},model:{value:(item.mediaOwner),callback:function ($$v) {_vm.$set(item, "mediaOwner", $$v)},expression:"item.mediaOwner"}}):(item.mediaOwner)?_c('span',[_c('small',[_vm._v(_vm._s(item.mediaOwner.friendlyName))])]):_vm._e()]}},{key:"item.campaign",fn:function(ref){
                var item = ref.item;
return [(item.mode==='prepare')?_c('v-select',{attrs:{"outlined":"","items":_vm.campaigns,"label":"Campaign","rules":_vm.requiredRule,"item-text":"name","return-object":"","dense":true},model:{value:(item.campaign),callback:function ($$v) {_vm.$set(item, "campaign", $$v)},expression:"item.campaign"}}):(item.campaign)?_c('span',[_c('small',[_vm._v(_vm._s(item.campaign.name))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.reportJobStatus(item).status === 'uploaded')?_c('v-btn',{attrs:{"icon":"","color":"primary","loading":_vm.playLoadingAnimation},on:{"click":function($event){return _vm.$emit('uploadPreparedJobsChild', item)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-play ")])],1):_vm._e(),(_vm.reportJobStatus(item).status === 'cancelled' || _vm.reportJobStatus(item).status === 'deleted' || _vm.reportJobStatus(item).status === 'exists')?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.purgePreparedJob(item)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-skull ")])],1):_vm._e(),(_vm.reportJobStatus(item).status === 'uploading' || _vm.reportJobStatus(item).status === 'uploaded')?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.deletePreparedJob(item)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }