<template>
    <div>
        <v-row class="mx-4" style="width: 95%">
            <!-- Filter by campaign -->
            <v-col cols="12" md="4" lg="3">
                <v-select
                    v-model="selectedCampaign"
                    :items="campaigns"
                    :item-text="item => item.name"
                    @change="campaignChange"
                    item-value="id"
                    label="Choose Campaign"
                    outlined
                    return-object
                />
            </v-col>
            <!-- Filter by bursts -->
            <v-col cols="12" md="4" lg="3">
                <v-select
                    :disabled="!selectedCampaign"
                    v-model="selectedBurst"
                    :items="bursts"
                    :item-text="item => item.name"
                    @change="burstChange"
                    item-value="id"
                    label="Choose Burst"
                    outlined
                    clearable
                    return-object
                />
            </v-col>
            <!-- Filter by screen -->
            <v-col cols="12" md="4" lg="3">
                <v-select
                    :disabled="!selectedBurst"
                    v-model="selectedScreen"
                    :items="screens"
                    :item-text="item => item.screen.name + ' - ' + item.screen.mediaOwner.friendlyName"
                    item-value="id"
                    label="Choose Screen"
                    outlined
                    clearable
                    return-object
                />
            </v-col>
            <!-- Day Parts-->
            <v-col cols="12" md="4" lg="3">
                <v-select
                    v-model="selectedDayPart"
                    :items="dayParts"
                    label="Choose part of day"
                    outlined
                    clearable
                    return-object
                />
            </v-col>
        </v-row>
        <v-row class='mx-4 mt-n6' style="width: 95%">
            <v-col cols="6">
                <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                            :disabled="!selectedCampaign"
                            :value="selectedDate"
                            outlined
                            range
                            label="Start/End Date"
                            append-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                    <div style="background-color: black;">
                        <v-row>
                            <v-col>
                                <v-date-picker
                                    v-model="selectedDate"
                                    :allowed-dates="allowedDates"
                                    range
                                    no-title
                                    scrollable
                                    @input="updateDate"
                                />
                            </v-col>
                            <v-col class="py-4" style="gap: 3em;">
                                <v-btn @click="goToDateRange(0,0)">Today Only</v-btn>
                                <v-btn @click="goToDateRange(-7,0)">Past Week Only</v-btn>
                                <v-btn @click="goToDateRange(0,7)">Coming Week Only</v-btn>
                                <v-btn @click="goToDateRange(-31,0)">Past Month Only</v-btn>
                                <v-btn @click="goToDateRange(0,31)">Coming Month Only</v-btn>
                                <v-checkbox
                                    v-model="overrideDates"
                                    label="View All Dates"
                                />
                            </v-col>
                        </v-row>
                    </div>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                            :disabled="!selectedCampaign"
                            :value="selectedStartTime"
                            outlined
                            range
                            label="Start Hour"
                            append-icon="mdi-clock-time-four-outline"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                    <v-time-picker
                        v-model="selectedStartTime"
                        format="24hr"
                    />
                </v-menu>
            </v-col>
            <v-col cols="3">
                <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                            :disabled="!selectedCampaign"
                            :value="selectedEndTime"
                            outlined
                            range
                            label="End Hour"
                            append-icon="mdi-clock-time-four-outline"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                    <v-time-picker
                        v-model="selectedEndTime"
                        format="24hr"
                    />
                </v-menu>
            </v-col>
        </v-row>    
        <v-row class="mx-8 mt-n6" style="gap: 3em;">
            <v-checkbox
                v-model="exportText"
                label="Export Text"
            />
            <v-checkbox
                v-model="exportOverlays"
                label="Export Overlays"
            />
        </v-row>
        <v-row class='mx-7' style="width: 93%">
            <v-col cols="12">
                <v-hover v-slot="{ hover }">
                    <v-btn
                        :color="hover ? 'primary darken-1' : 'primary'"
                        elevation="0"
                        @click="generateReport"
                        :style="{ width: '100%', transition: 'all 0.2s ease-in-out' }"
                    >
                        Generate Report
                    </v-btn>
                </v-hover>
                <span v-show="successMessage">Your report is now being generated, and will be emailed to you shortly.</span>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// Services
import CampaignController from '@/services/controllers/Campaign'
import ReportingController from '@/services/controllers/Reporting'
import moment from 'moment';

export default {
    name: 'ReportGenerating',
    props: {
        campaigns: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        // Selection
        selectedCampaign: null,
        selectedBurst: null,
        selectedScreen: null,
        // Data
        campaign: null,
        bursts: [],
        screens: [],
        // Checkboxes
        exportText: false,
        exportOverlays: false,
        overrideDates: false,
        dayParts: ['hour', 'day'],
        selectedDayPart: null,
        // Date pickers
        selectedDate: null,
        selectedStartTime: null,
        selectedEndTime: null,
        // Misc
        successMessage: false
    }),
    mounted() {
        this.successMessage= false
    },
    /* watch selectedBurst*/
    watch: {
        selectedScreen: function (val) {
            //console.log(val)
        },
    }, 
    computed: {
        allowedDates() {
            // If overrideDates is true, then return a function that allows all dates
            if (this.overrideDates) return () => true
            const now = new Date()
            let startAllowedDate = null
            let endAllowedDate = null

            // campaign dates (startDate, endDate)
            const campaignStartDate = this.selectedCampaign?.startDate
            const campaignEndDate = this.selectedCampaign?.endDate

            // burst dates (startDate, endDate)
            const burstStartDate = this.selectedBurst?.startDate
            const burstEndDate = this.selectedBurst?.endDate

            // screen dates  (startDate, endDate)
            const screenStartDate = this.selectedScreen?.startDate
            const screenEndDate = this.selectedScreen?.endDate

            if (screenStartDate && screenEndDate) {
                startAllowedDate = new Date(screenStartDate)
                endAllowedDate = new Date(screenEndDate)
            } else if (burstStartDate && burstEndDate) {
                startAllowedDate = new Date(burstStartDate)
                endAllowedDate = new Date(burstEndDate)
            } else if (campaignStartDate && campaignEndDate) {
                startAllowedDate = new Date(campaignStartDate)
                endAllowedDate = new Date(campaignEndDate)
            } else {
                startAllowedDate = now
                endAllowedDate = now
            }

            // remove the first date from startDateString
            startAllowedDate.setDate(startAllowedDate.getDate())

            // Add aa day to the end of endDateString
            endAllowedDate.setDate(endAllowedDate.getDate() + 1)

            // Extract only the date parts without time
            const startDateString = startAllowedDate.toISOString().split('T')[0]
            const endDateString = endAllowedDate.toISOString().split('T')[0]

            // Return a function
            return (date) => {
                const checkDate = new Date(date)
                const checkDateString = checkDate.toISOString().split('T')[0]
                return checkDateString >= startDateString && checkDateString <= endDateString
            }
        }

    },
    methods: {
        updateDate(value) {
            // Handle date selection if needed
            //console.log('Selected date:', value);
        },
        goToDateRange(s,e) {
            var start = moment().startOf('day').add(s, 'days').toISOString().split('T')[0];
            var end = moment().startOf('day').add(e, 'days').toISOString().split('T')[0];
            this.selectedDate = [start, end]
        },
        // On change handlers 
        campaignChange() {
            if(typeof this.selectedCampaign === 'object'){
                if(this.selectedCampaign == null) return
                this.bursts = []
                this.screens = []
                this.getCampaign(this.selectedCampaign.id)
            }
        },
        burstChange(){
            if(typeof this.selectedBurst === 'object'){
                if(this.selectedBurst == null) return
                this.screens = []

                // Iterate through cb and get its cbs for display
                this.selectedBurst.campaignBurstScreens.map((item) => {
                    this.screens.push(item)
                })
            }
        },

        /* On click / Calls */

        // Get campaign
        async getCampaign(campaignId) {
            // Get campaign
            const { data = null } = await CampaignController.getCampaign(campaignId)
            // Catch error
            .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

            // If the response contains valid data then append
            if(data) {
                this.campaign = data
                this.bursts = this.campaign.campaignBursts
            }
            else this.campaign = null
        },
        // Generate report
        async generateReport(){
            if(!this.selectedCampaign) {
                this.$root.$emit("snackbarError", "Please select a campaign")
                return
            } else if(!this.selectedDayPart) {
                this.$root.$emit("snackbarError", "Please select at least one day part")
                return
            } else {
                // get ids
                const burstId = this.selectedBurst ? this.selectedBurst.id : 0
                const screenId = this.selectedScreen ? this.selectedScreen.id : 0
                const startDate = this.selectedDate ? this.selectedDate[0] : ''
                const endDate = this.selectedDate ? this.selectedDate[1] : ''
                const startTime = this.selectedStartTime ? this.selectedStartTime : '00:00'
                const endTime = this.selectedEndTime ? this.selectedEndTime : '00:00'
                this.successMessage = true
                await ReportingController.reportGeneration(this.selectedCampaign.id, burstId, screenId, this.exportText, this.exportOverlays, this.selectedDayPart, startDate, endDate, startTime, endTime)
                .then(() => {
                    this.$root.$emit("snackbarSuccess", "Report has been sent to email!")
                })
                .catch((error) => {
                    this.$root.$emit("snackbarError", error.message)
                })
            }
        }
    }
}
</script>